import { Question } from "./types";

export const ANSWER_FEEDBACK_TIME = 5000;

export const COLLECTION_NAME = "GSK_USERS";

export const QUESTIONS: Question[] = [
  {
    id: 1,
    text: "1-Respiratory syncytial virus (RSV) infection significantly impacts the health of older, specially, those with comorbidities.",
    correctAnswerID: 1,
    answers: [
      { id: 1, char: "A", text: "True" },
      { id: 2, char: "B", text: "False" },
    ],
  },
  {
    id: 2,
    text: "RSV Pref3 vaccine delivers vaccine efficacy against RSV-LRTD of ____% in patients aged >= 60 years with >= 1 coexisting condition of interest.",
    correctAnswerID: 2,
    answers: [
      { id: 1, char: "A", text: "75.6%" },
      { id: 2, char: "B", text: "94.6%" },
    ],
  },
  {
    id: 1,
    text: "Are you wiling to discuss the importance of RSV prevention with your patients aged >= 60 ?",
    correctAnswerID: 3,
    answers: [
      { id: 1, char: "A", text: "Yes" },
      { id: 2, char: "B", text: "No" },
      { id: 3, char: "C", text: "Maybe" },
    ],
  },
];
