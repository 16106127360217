import { COLLECTION_NAME } from "./constants";
import { db } from "./firebase-config";
import "firebase/database";

export const AddEngagedUser = async () => {
  const userId = db.collection(COLLECTION_NAME).doc().id;
  const userContent = {
    createdAt: new Date().toISOString(),
  };
  await db.collection(COLLECTION_NAME).doc(userId).set(userContent);
  return { id: userId, ...userContent };
};

export const getEngagedUserCount = async () => {
  const usersDocs = (await db.collection(COLLECTION_NAME).get()).docs;
  return usersDocs.length;
};
