import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDrD29t3Nut_JkdONj13opK6rJqZ-cgYa8",
  authDomain: "saam-d8557.firebaseapp.com",
  projectId: "saam-d8557",
  storageBucket: "saam-d8557.appspot.com",
  messagingSenderId: "329842535582",
  appId: "1:329842535582:web:82bfe9940d5ddb9fff9f8f",
  measurementId: "G-HDXM5TT7F1",
};

let firebaseApp;

if (!firebase.apps.length) {
  firebaseApp = firebase.initializeApp(firebaseConfig);
} else {
  firebaseApp = firebase.app();
}

export const db = firebase.firestore();

export default firebaseApp;
