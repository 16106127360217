import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ANSWER_FEEDBACK_TIME, QUESTIONS } from "../utils/constants";

import SussesSound from "../sounds/success.mp3";
import WrongSound from "../sounds/wrong.mp3";
import { Answer } from "../utils/types";
import { AddEngagedUser, getEngagedUserCount } from "../utils/firebase-actions";

const SuccessAudio = new Audio(SussesSound);
const WrongAudio = new Audio(WrongSound);
function Game() {
  const navigate = useNavigate();
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState(0);
  const answerAction = useCallback(
    (answerId: number) => {
      if (userAnswer) return;
      const question = QUESTIONS[activeQuestionIndex];
      if (!question.answers.find((answer) => answer.id === answerId)) return;
      if (answerId === question.correctAnswerID) {
        SuccessAudio.play();
      } else {
        WrongAudio.play();
      }
      setUserAnswer(answerId);
      setTimeout(() => {
        setUserAnswer(0);
        onQuestionEnd();
      }, ANSWER_FEEDBACK_TIME);
    },
    [activeQuestionIndex, userAnswer]
  );
  const onQuestionEnd = useCallback(() => {
    if (activeQuestionIndex === 0) {
      AddEngagedUser();
    }
    setActiveQuestionIndex((activeQuestionIndex + 1) % QUESTIONS.length);
    setUserAnswer(0);
  }, [activeQuestionIndex, userAnswer]);

  const result = useCallback(
    (answer: Answer) => {
      {
        if (userAnswer && answer.id === userAnswer) {
          return userAnswer === QUESTIONS[activeQuestionIndex].correctAnswerID
            ? "true"
            : "wrong";
        }
        return "";
      }
    },
    [userAnswer, activeQuestionIndex]
  );
  return (
    <section className="game-container">
      <article className="question-container">
        <img src="/assets/question-bg.png" />
        <p> {QUESTIONS[activeQuestionIndex].text}</p>
      </article>
      <article className="answers-container">
        {QUESTIONS[activeQuestionIndex].answers.map((answer) => (
          <div
            key={answer.id}
            className={`answer-button ${result(answer)}`}
            onClick={() => {
              answerAction(answer.id);
            }}
          >
            <span>{answer.char}</span>
            {answer.text}
          </div>
        ))}
      </article>
    </section>
  );
}

export default Game;
