import React, { useEffect, useState } from "react";
import { getEngagedUserCount } from "../utils/firebase-actions";

export default function EngagedCount() {
  const [engagedUserCount, setEngagedUserCount] = useState(0);
  useEffect(() => {
    getEngagedUserCount().then((count) => setEngagedUserCount(count));
  }, []);
  return (
    <section className="engaged-count">
      <p>Engaged Users</p>
      <p>{engagedUserCount}</p>
    </section>
  );
}
